<template>
    <div></div>
</template>

<script>
export default {
  name: 'Activation',
  async mounted () {
    const activationToken = this.$route.query.activationToken
    await this.$store.dispatch('activate', activationToken)
      .then(async () => {
        await this.$store.dispatch('setEmailConfirmed')
        await this.$router.push('/')
      })
      .catch(() => {
        setTimeout(() => {
          this.$router.push('/')
        }, 5000)
      })
  },
}
</script>
